import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ADD_TOPUP,
  SAVE_TOPUP,
  GET_AIF_DATA,
  GET_PAN_APPLICATION,
  ADD_AFFILIATE_ENDPOINT,
  GET_AFFILIATE_DOC,
  AFFILIATE_APPROVAL,
  SUBMIT_AFFILIATE,
  GET_AFFILIATE_APPLICATION,
  GET_AFFILIATE_INVESTOR,
} from '../../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';
import { environment } from '../../../environments/environment';
import { Investment } from '../../core/model/investment.model';
import { AddAffiliate,AffiliateApproval} from '../../core/model/affiliate.model';

@Injectable({
  providedIn: 'root',
})
export class AffiliateService {
  constructor(private readonly http: HttpClient) { }

  getAllInvestments(pan: string, fundId: string) {
    const url = environment.APIURL + GET_PAN_APPLICATION + `/${pan}/${fundId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }



  saveTopup(pan: string,fundId:string) {
    const url = environment.APIURL + SAVE_TOPUP + `/${pan}`+ `/${fundId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  getAIFData() {
    const url =
      environment.APIURL + GET_AIF_DATA;
    return this.http.get(url);
  }


  addAffiliate(body: AddAffiliate) {
    const url = environment.APIURL + ADD_AFFILIATE_ENDPOINT;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  getAffiliateDocument(groupId: string) {
    const url = environment.APIURL + GET_AFFILIATE_DOC + `/${groupId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  // approvalAffiliate(groupId: string,affiliateApproval:string,comment :string,emailaddress:string) {
  //   const url = environment.APIURL + AFFILIATE_APPROVAL + `/${groupId}` + `/${affiliateApproval}` + `/${comment}` + `/${emailaddress}`;
  //   let headers = {} as any;
  // //  headers[AUTHORIZATION] =
  //   //  BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
  //   return this.http.post(url, { headers });
  // }
  

  approvalAffiliate(body: AffiliateApproval) {
    const url = environment.APIURL + AFFILIATE_APPROVAL;
    let headers = {} as any;
  //  headers[AUTHORIZATION] =
    //  BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body,{ headers });
  }

  submitAffiliate(groupId: string,relation:string) {
    const url = environment.APIURL + SUBMIT_AFFILIATE + `/${groupId}` + `/${relation}`;
    let headers = {} as any;
  //  headers[AUTHORIZATION] =
    //  BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, { headers });
  }

  getAffiliateApplication(groupId: string) {
    const url = environment.APIURL + GET_AFFILIATE_APPLICATION + `/${groupId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }
  
  
  getAffiliateInvestor(groupId: string) {
    const url = environment.APIURL + GET_AFFILIATE_INVESTOR + `/${groupId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }
  
  
}
